import {createRouter, createWebHistory, Router} from "vue-router";
import User from "@/navigation/model/user";
import Config from "@/config";

export default class NavigationRepository {
    private readonly router: Router;

    constructor() {
        this.router = createRouter({
            scrollBehavior(to, from, savedPosition) {
                const mainElement = document.getElementsByTagName("main")[0];
                mainElement.scrollTo(0, 0);
            },
            history: createWebHistory(process.env.BASE_URL),
            routes: [
                {
                    path: '/dashboard',
                    name: 'dashboard',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/dashboard/views/Dashboard.vue'),
                    meta: {
                        displayTitleKey: 'navigation.dashboard-title',
                        protected: true,
                        order: 0,
                    },
                },
                {
                    path: '/properties',
                    name: 'properties',
                    component: () => import(/* webpackChunkName: "properties" */ '@/properties/views/Properties.vue'),
                    meta: {
                        displayTitleKey: 'navigation.Properties-title',
                        protected: true,
                        order: 1,
                    },
                },
                {
                    path: '/property-registration',
                    name: 'property-registration',
                    component: () => import(/* webpackChunkName: "property-registration" */ '@/properties/views/PropertyRegistration.vue'),
                    meta: {
                        protected: true,
                    },
                },
                {
                    path: '/property-details',
                    name: 'property-details',
                    component: () => import(/* webpackChunkName: "property-details" */ '@/properties/views/PropertyDetails.vue'),
                    meta: {
                        protected: true,
                    },
                },
                {
                    path: '/introduction',
                    name: 'introduction',
                    component: () => import(/* webpackChunkName: "introduction" */ '@/info/views/Introduction.vue'),
                    meta: {
                        displayTitleKey: 'navigation.introduction-title',
                        protected: false,
                    },
                },
                {
                    path: '/success-stories',
                    name: 'success-stories',
                    component: () => import(/* webpackChunkName: "success-stories" */ '@/info/views/SuccessStories.vue'),
                    meta: {
                        displayTitleKey: 'navigation.success-stories-title',
                        protected: false,
                        order: 3,
                    },
                },
                {
                    path: '/quick-check',
                    name: 'quick-check',
                    component: () => import(/* webpackChunkName: "quick-check" */ '@/quickcheck/views/Quickcheck.vue'),
                    meta: {
                        displayTitleKey: 'navigation.quick-check-title',
                        protected: false,
                        order: 4,
                    },
                },
                {
                    path: '/service-providers',
                    name: 'service-providers',
                    component: () => import(/* webpackChunkName: "service-providers" */ '@/serviceProviders/views/ServiceProviders.vue'),
                    meta: {
                        displayTitleKey: 'navigation.service-providers-title',
                        protected: false,
                        order: 5,
                    },
                },
                {
                    path: '/funding-programs',
                    name: 'funding-programs',
                    component: () => import(/* webpackChunkName: "funding-programs" */ '@/fundingPrograms/views/FundingPrograms.vue'),
                    meta: {
                        displayTitleKey: 'navigation.funding-programs-title',
                        protected: false,
                        order: 6,
                    },
                },
                {
                    path: '/measures',
                    name: 'measures',
                    component: () => import(/* webpackChunkName: "measures" */ '@/measures/views/Measures.vue'),
                    meta: {
                        displayTitleKey: 'navigation.measures-title',
                        protected: false,
                        order: 7,
                    },
                },
                {
                    path: '/quickcheck-results',
                    name: 'quickcheck-results',
                    props: true,
                    component: () => import(/* webpackChunkName: "quickcheckResults" */ '@/quickcheck/views/QuickcheckResults.vue'),
                    meta: {
                        displayTitleKey: 'navigation.quickcheckResults-title',
                        protected: false,
                        order: 8,
                    },
                },
                {
                    path: '/profile',
                    name: 'profile',
                    component: () => import(/* webpackChunkName: "userProfile" */ '@/user/views/UserProfile.vue'),
                    meta: {
                        displayTitleKey: 'navigation.profile-title',
                        showInNavigationBar: () => true,
                        showInSideNavigationBar: true,
                        protected: true,
                        order: 9,
                    },
                },
                {
                    path: '/messages',
                    name: 'messages',
                    component: () => import(/* webpackChunkName: "userProfile" */ '@/messaging/views/Messages.vue'),
                    meta: {
                        displayTitleKey: 'navigation.messages',
                        showInNavigationBar: () => true,
                        showInSideNavigationBar: true,
                        protected: true,
                        order: 13,
                    },
                },
                {
                    path: '/user-role-select',
                    name: 'user-role-select',
                    component: () => import(/* webpackChunkName: "userRoleSelection" */ '@/user/views/UserRoleSelection.vue'),
                    meta: {
                        displayTitleKey: 'navigation.userrole-selection',
                        showInNavigationBar: () => true,
                        showInSideNavigationBar: true,
                        protected: true,
                        order: 10,
                    },
                },
                {
                    path: '/user-profile-edit',
                    name: 'user-profile-edit',
                    component: () => import(/* webpackChunkName: "userProfileEdit" */ '@/user/views/UserProfileEdit.vue'),
                    meta: {
                        displayTitleKey: 'navigation.user-profile-edit',
                        showInNavigationBar: () => true,
                        showInSideNavigationBar: true,
                        protected: true,
                        order: 11,
                    },
                },
                {
                    path: '/user-password-edit',
                    name: 'user-password-edit',
                    component: () => import(/* webpackChunkName: "userPasswordEdit" */ '@/user/views/UserPasswordEdit.vue'),
                    meta: {
                        displayTitleKey: 'navigation.user-password-edit',
                        showInNavigationBar: () => true,
                        showInSideNavigationBar: true,
                        protected: true,
                        order: 12,
                    },
                },
                {
                    path: '/service-provider-create',
                    name: 'service-provider-create',
                    component: () => import(/* webpackChunkName: "serviceProviderCreate" */ '@/serviceProviders/views/ServiceProviderCreate.vue'),
                    meta: {
                        displayTitleKey: 'navigation.service-provider-create',
                        protected: true,
                        order: 13,
                    },
                },
                {
                    path: '/service-provider-details/:key?',
                    name: 'service-provider-details',
                    component: () => import(/* webpackChunkName: "serviceProviderCreate" */ '@/serviceProviders/views/ServiceProviderDetails.vue'),
                    meta: {
                        displayTitleKey: 'navigation.service-provider-details',
                        protected: false,
                        order: 14,
                    },
                },
                {
                    path: '/service-provider-profile',
                    name: 'service-provider-profile',
                    component: () => import(/* webpackChunkName: "serviceProviderProfile" */ '@/serviceProviders/views/ServiceProviderDetails.vue'),
                    meta: {
                        displayTitleKey: 'navigation.service-provider-profile',
                        protected: false,
                        order: 14,
                    },
                },
                {
                    path: '/basic-auth',
                    name: 'basic-auth',
                    component: () => import(/* webpackChunkName: "basicAuth" */ '@/user/views/BasicAuth.vue'),
                    meta: {},
                },
                {
                    path: '/confirmation-logout',
                    name: 'confirmation-logout',
                    component: () => import(/* webpackChunkName: "logoutConfirmation" */ '@/user/views/LogoutConfirmation.vue'),
                    meta: {
                        protected: false,
                    },
                },
                {
                    path: '/confirmation-email',
                    name: 'confirmation-email',
                    component: () => import(/* webpackChunkName: "emailConfirmation" */ '@/user/views/EmailConfirmation.vue'),
                    meta: {
                        protected: false,
                    },
                },
                {
                    path: '/imprint',
                    name: 'imprint',
                    component: () => import(/* webpackChunkName: "imprint" */ '@/info/views/Imprint.vue'),
                    meta: {
                        protected: false,
                    },
                },
                {
                    path: '/data',
                    name: 'data',
                    component: () => import(/* webpackChunkName: "data" */ '@/info/views/Data.vue'),
                    meta: {
                        protected: false,
                    },
                },
                {
                    path: '/shared-properties',
                    name: 'shared-properties',
                    component: () => import(/* webpackChunkName: "sharedProperties" */ '@/properties/views/SharedProperties.vue'),
                    meta: {
                        protected: true,
                    },
                },
                {
                    path: '/measure-editing',
                    name: 'measure-editing',
                    component: () => import(/* webpackChunkName: "measureEditing" */ '@/measures/views/MeasureEditing.vue'),
                    meta: {
                        protected: true,
                    },
                },
                {
                    path: '/measure-details',
                    name: 'measure-details',
                    component: () => import(/* webpackChunkName: "measureDetails" */ '@/measures/views/MeasureDetails.vue'),
                    meta: {
                        protected: false,
                    },
                },
                {
                    path: '/ar-test',
                    name: 'ar-test',
                    component: () => import(/* webpackChunkName: "arTest" */ '@/info/views/ARTest.vue'),
                    meta: {
                        protected: false,
                    },
                },
                {
                    path: '/video-stream-test',
                    name: 'video-stream-test',
                    component: () => import(/* webpackChunkName: "videoStreamTest" */ '@/info/views/VideoStreamTest.vue'),
                    meta: {
                        protected: false,
                    },
                },
                {
                    path: '/ar-hit-test',
                    name: 'ar-hit-test',
                    component: () => import(/* webpackChunkName: "arHitTest" */ '@/info/views/ARHitTest.vue'),
                    meta: {
                        protected: false,
                    },
                },
                {
                    path: '/style',
                    name: 'style',
                    component: () => import(/* webpackChunkName: "arHitTest" */ '@/info/views/Styles.vue'),
                    meta: {
                        protected: false,
                    },
                },
            ],
        });

        this.router.beforeEach((to, from, next) => {
            if (to.name === 'basic-auth') {
                next();
                return;
            } else if (!Config.getCookie(Config.basicAuthCookieName)) {
                this.router.push('/basic-auth');
                return;
            } else if (to.meta.protected) {
                this.getAuthenticatedUser()
                    .then((user: User | null) => {
                        if (user) {
                            next();
                            return;
                        }
                        // store route to go to after coming back from login
                        localStorage.setItem('nextRoute', JSON.stringify(to.name));
                        // to login page, if !user
                        window.location.replace(Config.loginUrl);
                    })
                    .catch((reason: unknown) => {
                        // default to quick check-page on error
                        this.router.push({
                            name: 'quick-check',
                        });
                        window.alert(`Service problem: ${reason}`);
                    });
            } else {
                next();
            }
        });
    }

    async getAuthenticatedUser(): Promise<User | null> {

        return fetch(`${Config.backendBaseUrl}/api/user/authuser`, {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            credentials: 'include'
        })
            .then((response) => {
                if (response.status === 401) {
                    return null;
                }
                if (response.status !== 200) {
                    throw new Error(`${response.status} ${response.statusText}`);
                }

                return response.json();
            })
            .catch((e) => {
                throw e;
            });
    }

    getRouter(): Router {
        return this.router;
    }

}
