import SortDirection from '@/common/table/types/sortDirection';
import Config from '@/config';
import {PaginatedMeasureOverviewResult} from '@/measures/model/paginatedMeasureOverviewResult';
import MeasureRepository from '@/measures/repository/measureRepository';
import SortBy from '@/measures/model/sortBy';
import {Measure} from "@/measures/model/measure";
import SelectCategory from "@/measures/model/selectCategory";
import {ImageAnalysisResponse} from "@/measures/model/imageAnalysisResponse";

export class MeasuresRepositoryImpl implements MeasureRepository {

    private measuresBackendUrl = `${Config.backendBaseUrl}/api/measures`;

    async searchMeasures(searchTerm: string,
                         sortBy: SortBy,
                         sortDirection: SortDirection,
                         page: number,
                         pageSize: number, measureTypes: string[], minFixedCost: number, maxFixedCost: number, minCost: number, maxCost: number): Promise<PaginatedMeasureOverviewResult> {

        const sortParam = this.mapSortByToUrlParameterValue(sortBy);
        const orderParam = this.mapSortOrderToUrlParameterValue(sortDirection);

        const parameters = {
            searchTerm: searchTerm,
            sortParam: sortParam,
            orderParam: orderParam,
            page: page,
            pageSize: pageSize,
            measureTypes: measureTypes,
            minFixedCost: minFixedCost,
            maxFixedCost: maxFixedCost,
            minCost: minCost,
            maxCost: maxCost
        }

        const queryUrl = `${this.measuresBackendUrl}/search`;
        const requestParams: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? ''
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(parameters)
        };

        return this.executeRequest<PaginatedMeasureOverviewResult>(queryUrl, requestParams);
    }

    getMeasureByKey(measureKey: string): Promise<Measure | null> {

        const requestUrl = `${this.measuresBackendUrl}/${measureKey}`;
        const requestParams: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            method: 'GET',
            credentials: 'include'
        };

        return this.executeRequestWithNullableResult<Measure | null>(requestUrl, requestParams);
    }

    updateMeasure(measure: Measure): Promise<boolean> {

        const measureRequest = {
            key: measure.key,
            type: measure.type,
            measureName: measure.name,
            description: measure.description,
            insulationStrength: measure.insulationStrength,
            producerEffortNumber: measure.producerEffortNumber,
            heatLoss: measure.heatLoss,
            storageVolume: measure.storageVolume,
            thermalConductionGroup: measure.thermalConductionGroup,
            uwValue: measure.uwValue,
            insulationConstructionType: measure.insulationConstructionType,
            costPerUnit: measure.costPerUnit,
            unit: measure.unit,
            saving: measure.saving
        }

        const requestUrl = `${this.measuresBackendUrl}/updateMeasure`;
        const requestParams: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            body: JSON.stringify(measureRequest),
            method: 'POST',
            credentials: 'include'
        };

        return this.executeRequest<boolean>(requestUrl, requestParams);
    }

    deleteMeasureByKey(measureKey: string): Promise<boolean> {

        const requestUrl = `${this.measuresBackendUrl}/${measureKey}`;
        const requestParams: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            method: 'DELETE',
            credentials: 'include'
        };

        return this.executeRequest<boolean>(requestUrl, requestParams);
    }

    getTypeCategories(typeCategory: SelectCategory): Promise<string[] | null> {
        const queryUrl = `${this.measuresBackendUrl}/getTypeCategories?typeCategory=${typeCategory}`;
        const requestParams: RequestInit = {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? ''
            },
            method: 'GET',
            credentials: 'include'
        };

        return this.executeRequestWithNullableResult<string[]>(queryUrl, requestParams);
    }

    createTypeCategory(name: string, typeCategory: SelectCategory): Promise<boolean> {
        const queryUrl = `${this.measuresBackendUrl}/createTypeCategory?name=${name}&typeCategory=${typeCategory}`;
        const requestParams: RequestInit = {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? ''
            },
            method: 'POST',
            credentials: 'include'
        };

        return this.executeRequest<boolean>(queryUrl, requestParams);
    }

    deleteTypeCategory(name: string, typeCategory: SelectCategory): Promise<boolean> {
        const queryUrl = `${this.measuresBackendUrl}/deleteTypeCategory?name=${name}&typeCategory=${typeCategory}`;
        const requestParams: RequestInit = {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? ''
            },
            method: 'DELETE',
            credentials: 'include'
        };

        return this.executeRequest<boolean>(queryUrl, requestParams);
    }

    requestImageAnalysis(image: string): Promise<ImageAnalysisResponse> {
        const imageRequest = {
            image: image
        }

        const requestUrl = `${this.measuresBackendUrl}/requestImageAnalysis`;
        const requestParams: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            body: JSON.stringify(imageRequest),
            method: 'POST',
            credentials: 'include'
        };

        return this.executeRequest<ImageAnalysisResponse>(requestUrl, requestParams);
    }

    private async executeRequest<ResponseType>(requestUrl: string, requestParams: RequestInit): Promise<ResponseType> {
        try {
            const response = await fetch(requestUrl, requestParams);
            return response.ok ? response.json() : Promise.reject();
        } catch (e) {
            return Promise.reject(e);
        }
    }

    private async executeRequestWithNullableResult<ResponseType>(requestUrl: string, requestParams: RequestInit): Promise<ResponseType | null> {
        try {
            const response = await fetch(requestUrl, requestParams);
            if (response.status === 204) {
                return null;
            }
            return response.ok ? response.json() : Promise.reject();
        } catch (e) {
            return Promise.reject(e);
        }
    }

    private mapSortByToUrlParameterValue(sortBy: SortBy): string {
        switch (sortBy) {
            case SortBy.TYPE:
                return 'TYPE';
            case SortBy.NAME:
                return 'NAME';
            case SortBy.FIXED_COST:
                return 'FIXED_COST';
            case SortBy.COST:
                return 'COST_PER_UNIT';
            case SortBy.SAVING:
                return 'SAVING';
            case SortBy.AMORTISATION:
                return 'AMORTISATION';
            case SortBy.BEG:
                return 'BEG';
            default:
                return 'TYPE';
        }
    }

    private mapSortOrderToUrlParameterValue(order: SortDirection): string {
        switch (order) {
            case SortDirection.ASCENDING:
                return 'ASC';
            case SortDirection.DESCENDING:
                return 'DESC';

        }
    }
}
