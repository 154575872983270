import Config from '@/config';
import {PaginatedPropertiesResult} from '@/properties/model/paginatedPropertiesResult';
import Property from '@/properties/model/property';
import SortBy from '@/properties/model/sortBy';
import SortDirection from '@/common/table/types/sortDirection';
import PropertyRepository from '@/properties/repository/propertyRepository';
import DetailedProperty from "@/properties/model/detailedProperty";
import {ImageTypes} from "@/properties/model/imageTypes";
import {ImagesResponse} from "@/properties/model/imagesResponse";
import {ImageKeysResponse} from "@/properties/model/imageKeysResponse";
import {DetailedEstimation} from "@/properties/model/detailedEstimation";
import {Sharing} from "@/properties/model/sharing";
import {PropertyKeyResponse} from "@/properties/model/propertyKeyResponse";
import {EnergyType} from "@/properties/model/energyType";
import {ConsumptionEntry} from "@/properties/model/consumptionEntry";
import SharingSortBy from "@/properties/model/sharingSortBy";
import {PaginatedSharingResult} from "@/properties/model/paginatedSharingResult";
import {AffectedServiceProviderInformation} from "@/properties/model/affectedServiceProviderInformation";
import {ImprovementSuggestion} from "@/properties/model/improvementSuggestion";
// import {Promise} from "cypress/types/cy-bluebird";
import {ImageAnalysis} from "@/properties/model/imageAnalysis";

interface PropertyNameResponse {
    name: string,
}

interface ImageStoreResponse {
    imageKey: string
}

interface ImageResponse {
    image: string
}

export default class PropertyRepositoryImpl implements PropertyRepository {

    private propertiesBackendUrl = `${Config.backendBaseUrl}/api/properties`;

    async getPropertyByKey(propertyKey: string): Promise<Property> {

        const requestUrl = `${this.propertiesBackendUrl}/${propertyKey}`;
        const requestParams: RequestInit = {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            method: 'GET',
            credentials: 'include'
        };

        return this.executeRequest<Property>(requestUrl, requestParams);
    }

    async getDetailedPropertyByKey(propertyKey: string): Promise<DetailedProperty> {

        const requestUrl = `${this.propertiesBackendUrl}/${propertyKey}`;
        const requestParams: RequestInit = {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            method: 'GET',
            credentials: 'include'
        };

        return this.executeRequest<DetailedProperty>(requestUrl, requestParams);
    }

    async getDetailedPropertiesByUserKey(): Promise<DetailedProperty[] | null> {
        const requestUrl = `${this.propertiesBackendUrl}/getProperties`;
        const requestParams: RequestInit = {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            method: 'GET',
            credentials: 'include'
        };

        return this.executeRequestWithNullableResult<DetailedProperty[]>(requestUrl, requestParams);
    }

    async searchProperties(searchTerm: string,
                           sortBy: SortBy,
                           sortDirection: SortDirection,
                           page: number,
                           pageSize: number): Promise<PaginatedPropertiesResult> {

        const sortParam = this.mapSortByToUrlParameterValue(sortBy);
        const orderParam = this.mapSortOrderToUrlParameterValue(sortDirection);
        const queryUrl = `${this.propertiesBackendUrl}/search?searchTerm=${searchTerm}&sortBy=${sortParam}&order=${orderParam}&page=${page}&pageSize=${pageSize}`;
        const requestParams: RequestInit = {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            method: 'GET',
            credentials: 'include'
        };

        return this.executeRequest<PaginatedPropertiesResult>(queryUrl, requestParams);
    }

    async searchSharings(searchTerm: string,
                         categorySearchTerms: string[],
                         sortBy: SharingSortBy,
                         sortDirection: SortDirection,
                         page: number,
                         pageSize: number): Promise<PaginatedSharingResult> {

        const sortParam = this.mapSharingSortByToUrlParameterValue(sortBy);
        const orderParam = this.mapSortOrderToUrlParameterValue(sortDirection);
        const queryUrl = `${this.propertiesBackendUrl}/searchSharings?searchTerm=${searchTerm}&categorySearchTerms=${categorySearchTerms.join('_')}&sortBy=${sortParam}&order=${orderParam}&page=${page}&pageSize=${pageSize}`;
        const requestParams: RequestInit = {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            method: 'GET',
            credentials: 'include'
        };

        return this.executeRequest<PaginatedSharingResult>(queryUrl, requestParams);
    }

    async storeDetailedProperty(property: DetailedProperty): Promise<DetailedProperty> {

        const requestUrl = `${this.propertiesBackendUrl}/new`;
        const requestParams: RequestInit = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            body: JSON.stringify(property),
        };

        return this.executeRequest<DetailedProperty>(requestUrl, requestParams);
    }

    async storeImage(image: string, imageType: number, propertyKey: string, floorNumber: number): Promise<string> {

        const imageRequest = {
            image: image,
            imageType: imageType,
            propertyKey: propertyKey,
            floorNumber: floorNumber
        }

        const requestUrl = `${this.propertiesBackendUrl}/newImage`;
        const requestParams: RequestInit = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            body: JSON.stringify(imageRequest),
        };

        const response: ImageStoreResponse = await this.executeRequest<ImageStoreResponse>(requestUrl, requestParams)
            .catch(() => {
                return {
                    imageKey: ''
                }
            });
        return response.imageKey;
    }

    async storeNewThumbnail(originalImageKey: string, thumbnail: string): Promise<string> {
        const thumbnailRequest = {
            originalImageKey: originalImageKey,
            image: thumbnail
        }

        const requestUrl = `${this.propertiesBackendUrl}/newThumbnail`;
        const requestParams: RequestInit = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            body: JSON.stringify(thumbnailRequest)
        };
        const response: ImageStoreResponse = await this.executeRequest<ImageStoreResponse>(requestUrl, requestParams)
            .catch(() => {
                return {
                    imageKey: ''
                }
            });
        return response.imageKey;
    }

    async getImage(imageKey: string): Promise<string> {

        const requestUrl = `${this.propertiesBackendUrl}/getImage?key=${imageKey}`;
        const requestParams: RequestInit = {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
        };

        const response: ImageResponse = await this.executeRequest<ImageResponse>(requestUrl, requestParams)
            .catch(() => {
                return {
                    image: ''
                }
            });
        return response.image;
    }

    async getThumbnail(originalImageKey: string): Promise<string> {
        const requestUrl = `${this.propertiesBackendUrl}/getThumbnail?originalImageKey=${originalImageKey}`;
        const requestParams: RequestInit = {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
        };

        const response: ImageStoreResponse = await this.executeRequest<ImageStoreResponse>(requestUrl, requestParams)
            .catch(() => {
                return {
                    imageKey: ''
                }
            });
        return response.imageKey;
    }

    async getImagesByType(type: ImageTypes, propertyKey: string): Promise<ImagesResponse[] | null> {
        const requestUrl = `${this.propertiesBackendUrl}/getImagesByType?type=${type}&propertyKey=${propertyKey}`;
        const requestParams: RequestInit = {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
        };

        return await this.executeRequestWithNullableResult<ImagesResponse[] | null>(requestUrl, requestParams);
    }

    async getImageKeysByType(type: ImageTypes, propertyKey: string): Promise<ImageKeysResponse[] | null> {
        const requestUrl = `${this.propertiesBackendUrl}/getImageKeysByType?type=${type}&propertyKey=${propertyKey}`;
        const requestParams: RequestInit = {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
        };

        return await this.executeRequestWithNullableResult<ImageKeysResponse[] | null>(requestUrl, requestParams);
    }

    async deleteImage(imageKey: string): Promise<boolean> {

        const requestUrl = `${this.propertiesBackendUrl}/deleteImage?imageKey=${imageKey}`;
        const requestParams: RequestInit = {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
        };

        return this.executeRequest<boolean>(requestUrl, requestParams);
    }

    async deleteSharing(propertyKey: string, serviceProviderKey: string): Promise<PropertyNameResponse> {

        const requestUrl = `${this.propertiesBackendUrl}/deleteSharing?propertyCopyKey=${propertyKey}&serviceProviderKey=${serviceProviderKey}`;
        const requestParams: RequestInit = {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
        };

        return this.executeRequest<PropertyNameResponse>(requestUrl, requestParams);
    }

    async updateProperty(propertyKey: string, newPropertyData: DetailedProperty): Promise<DetailedProperty> {

        const requestUrl = `${this.propertiesBackendUrl}/update`;
        const requestParams: RequestInit = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            body: JSON.stringify(newPropertyData),
        };

        return this.executeRequest<DetailedProperty>(requestUrl, requestParams);
    }

    async deleteProperty(propertyKey: string): Promise<AffectedServiceProviderInformation[]> {

        const requestUrl = `${this.propertiesBackendUrl}/${propertyKey}`;
        const requestParams: RequestInit = {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            method: 'DELETE',
            credentials: 'include'
        };

        return this.executeRequest<AffectedServiceProviderInformation[]>(requestUrl, requestParams);
    }

    async sharePropertyInformation(propertyKey: string, serviceProviderKey: string, categories: number[], propertyName: string, serviceProviderName: string): Promise<PropertyNameResponse | null> {

        const sharePropertyRequest = {
            propertyKey: propertyKey,
            serviceProviderKey: serviceProviderKey,
            categories: categories,
            propertyName: propertyName,
            serviceProviderName: serviceProviderName
        }

        const requestUrl = `${this.propertiesBackendUrl}/updateSharedPropertyInformation`;
        const requestParams: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            body: JSON.stringify(sharePropertyRequest),
            method: 'PUT',
            credentials: 'include'
        };

        return this.executeRequestWithNullableResult<PropertyNameResponse | null>(requestUrl, requestParams);
    }

    getSharingsByPropertyAndServiceProvider(serviceProviderKey: string, propertyKey: string): Promise<Sharing | null> {

        const requestUrl = `${this.propertiesBackendUrl}/getSharedPropertyInformation?serviceProviderKey=${serviceProviderKey}&propertyKey=${propertyKey}`;
        const requestParams: RequestInit = {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            method: 'GET',
            credentials: 'include'
        };

        return this.executeRequestWithNullableResult<Sharing | null>(requestUrl, requestParams);
    }

    getSharingsByUserAndServiceProvider(userKey: string, serviceProviderKey: string): Promise<PropertyKeyResponse[] | null> {

        const requestUrl = `${this.propertiesBackendUrl}/getSharedProperties?userKey=${userKey}&serviceProviderKey=${serviceProviderKey}`;
        const requestParams: RequestInit = {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            method: 'GET',
            credentials: 'include'
        };
        return this.executeRequestWithNullableResult<PropertyKeyResponse[] | null>(requestUrl, requestParams);
    }

    updateConsumptionData(propertyKey: string, date: Date, value: number, type: EnergyType): Promise<boolean> {
        const requestBody = {
            propertyKey: propertyKey,
            value: value,
            date: date,
            type: type.toString()
        }

        const requestUrl = `${this.propertiesBackendUrl}/updateConsumptionData`;
        const requestParams: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            body: JSON.stringify(requestBody),
            method: 'POST',
            credentials: 'include'
        };

        return this.executeRequest<boolean>(requestUrl, requestParams);
    }

    deleteConsumptionDataEntry(propertyKey: string, date: Date, type: EnergyType): Promise<boolean> {
        const requestUrl = `${this.propertiesBackendUrl}/deleteConsumptionDataEntry?propertyKey=${propertyKey}&date=${date}&type=${type}`;
        const requestParams: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            method: 'DELETE',
            credentials: 'include'
        };

        return this.executeRequest<boolean>(requestUrl, requestParams);
    }

    getConsumptionData(propertyKey: string, type: EnergyType): Promise<ConsumptionEntry[] | null> {
        const requestUrl = `${this.propertiesBackendUrl}/getConsumptionData?propertyKey=${propertyKey}&energyType=${type.valueOf()}`;
        const requestParams: RequestInit = {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            method: 'GET',
            credentials: 'include'
        };
        return this.executeRequestWithNullableResult<ConsumptionEntry[] | null>(requestUrl, requestParams);
    }

    getDetailedEstimation(propertyKey: string): Promise<DetailedEstimation | null> {
        const requestUrl = `${this.propertiesBackendUrl}/getDetailedEstimation?propertyKey=${propertyKey}`;
        const requestParams: RequestInit = {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            method: 'GET',
            credentials: 'include'
        };
        return this.executeRequestWithNullableResult<DetailedEstimation | null>(requestUrl, requestParams);
    }

    requestDetailedEstimation(detailedProperty: DetailedProperty): Promise<DetailedEstimation | null> {
        const requestUrl = `${this.propertiesBackendUrl}/requestDetailedEstimation`;
        const requestParams: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            body: JSON.stringify(detailedProperty),
            method: 'POST',
            credentials: 'include'
        };
        return this.executeRequestWithNullableResult<DetailedEstimation | null>(requestUrl, requestParams);
    }

    requestNewEstimation(detailedEstimation: DetailedEstimation): Promise<DetailedEstimation | null> {
        const requestUrl = `${this.propertiesBackendUrl}/requestNewEstimation`;
        const requestParams: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            body: JSON.stringify(detailedEstimation),
            method: 'POST',
            credentials: 'include'
        };
        return this.executeRequestWithNullableResult<DetailedEstimation | null>(requestUrl, requestParams);
    }

    getImprovementSuggestion(propertyKey: string): Promise<ImprovementSuggestion[] | null> {
        const requestUrl = `${this.propertiesBackendUrl}/getImprovementSuggestion?propertyKey=${propertyKey}`;
        const requestParams: RequestInit = {
            headers: {
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            method: 'GET',
            credentials: 'include'
        };
        return this.executeRequestWithNullableResult<ImprovementSuggestion[] | null>(requestUrl, requestParams);
    }

    requestSuggestionForImprovement(detailedEstimation: DetailedEstimation, propertyKey: string): Promise<ImprovementSuggestion[] | null> {
        const requestUrl = `${this.propertiesBackendUrl}/requestSuggestionForImprovement?propertyKey=${propertyKey}`;
        const requestParams: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            body: JSON.stringify(detailedEstimation),
            method: 'POST',
            credentials: 'include'
        };
        return this.executeRequestWithNullableResult<ImprovementSuggestion[]>(requestUrl, requestParams);
    }

    deleteImprovementSuggestions(): Promise<boolean> {
        const requestUrl = `${this.propertiesBackendUrl}/deleteImprovementSuggestions`;
        const requestParams: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            method: 'DELETE',
            credentials: 'include'
        };
        return this.executeRequest<boolean>(requestUrl, requestParams);
    }

    requestImageAnalysis(image: string, model: string, device: string, focalLength: string): Promise<ImageAnalysis[] | null> {
        const imageAnalysisRequest = {
            image: image,
            model: model ?? 'Apple',
            device: device ?? 'iPhone 14 Pro',
            focalLength: focalLength ?? '0'
        };
        const requestUrl = `${this.propertiesBackendUrl}/requestImageAnalysis`;
        const requestParams: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Config.getCookie(Config.basicAuthCookieName) ?? '',
            },
            body: JSON.stringify(imageAnalysisRequest),
            method: 'POST',
            credentials: 'include'
        };
        return this.executeRequestWithNullableResult<ImageAnalysis[] | null>(requestUrl, requestParams);
    }

    private async executeRequest<ResponseType>(requestUrl: string, requestParams: RequestInit): Promise<ResponseType> {
        try {
            const response = await fetch(requestUrl, requestParams);

            if (response.status === 401) {
                // login in if not authenticated
                window.location.replace(Config.loginUrl);
            }
            return response.ok ? response.json() : Promise.reject(false);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    private async executeRequestWithNullableResult<ResponseType>(requestUrl: string, requestParams: RequestInit): Promise<ResponseType | null> {
        try {
            const response = await fetch(requestUrl, requestParams);
            if (response.status === 204) {
                return null;
            }
            return response.ok ? response.json() : Promise.reject();
        } catch (e) {
            return Promise.reject(e);
        }
    }

    private mapSortByToUrlParameterValue(sortBy: SortBy): string {
        switch (sortBy) {
            case SortBy.NAME:
                return 'NAME';
            case SortBy.DESCRIPTION:
                return 'DESCRIPTION';
            case SortBy.ADDRESS:
                return 'ADDRESS';
            case SortBy.ENERGY_CLASS:
                return 'ENERGY_CLASS';
            case SortBy.INDICATOR_OF_EFFICIENCY:
                return 'ENERGY_EFFICIENCY_INDICATOR';
        }
    }

    private mapSharingSortByToUrlParameterValue(sortBy: SharingSortBy): string {
        switch (sortBy) {
            case SharingSortBy.PROPERTY_NAME:
                return 'PROPERTY_NAME';
            case SharingSortBy.SERVICE_PROVIDER_NAME:
                return 'SERVICE_PROVIDER_NAME';
            default:
                return '';
        }
    }

    private mapSortOrderToUrlParameterValue(order: SortDirection): string {
        switch (order) {
            case SortDirection.ASCENDING:
                return 'ASC';
            case SortDirection.DESCENDING:
                return 'DESC';

        }
    }
}
